// ** Initial State
const initialState = {
  allDataFood: [],
  dataFood: [],
  totalFood: 1,
  paramsFood: {},
  selectedFood: null,
  isLoadingFood: false,
  isLoadingFoodCreate: false,
  isLoadingFoodUpdate: false,
  isLoadingImport: false

}

const foods = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA_FOOD':
      return { ...state, allDataFood: action.data }
    case 'GET_DATA_FOOD':
      return {
        ...state,
        dataFood: action.data,
        totalFood: action.totalPages,
        paramsFood: action.params
      }
    case 'GET_FOOD':
      return { ...state, selectedFood: action.selectedFood }
    case 'ADD_FOOD':
      return { ...state }
    case 'DELETE_FOOD':
      return { ...state }
    case 'START_LOADING':
      return { ...state, isLoadingFood: true }
    case 'END_LOADING':
      return { ...state, isLoadingFood: false }
    case 'START_LOADING_UPDATE':
      return { ...state, isLoadingFoodUpdate: true }
    case 'END_LOADING_UPDATE':
      return { ...state, isLoadingFoodUpdate: false }
    case 'START_LOADING_CREATE':
      return { ...state, isLoadingFoodCreate: true }
    case 'END_LOADING_CREATE':
      return { ...state, isLoadingFoodCreate: false }
      case 'START_LOADING_IMPORT':
        return { ...state, isLoadingImport: true }
      case 'END_LOADING_IMPORT':
        return { ...state, isLoadingImport: false }
    default:
      return { ...state }
  }
}
export default foods
