// ** Initial State
const initialState = {
  allDataPatient: [],
  dataPatient: [],
  dataDoctor: [],
  paramsDoctor: {},
  totalPatient: 1,
  paramsPatient: {},
  selectedPatient: null,
  isLoadingPatient: false,
  isLoadingPatientCreate: false,
  isLoadingPatientUpdate: false,
  patientStatistics: []
}

const patients = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA_PATIENT':
      return { ...state, allDataPatient: action.data }
    case 'GET_DATA_PATIENT':
      return {
        ...state,
        dataPatient: action.data,
        totalPatient: action.totalPages,
        paramsPatient: action.params
      }
    case 'GET_DOCTORS':
      return {
        ...state,
        dataDoctor: action.data,
        paramsDoctor: action.params
      }
    case 'GET_PATIENT_STATISTICS':
      return {
        ...state,
        patientStatistics: action.data
      }
    case 'GET_PATIENT':
      return { ...state, selectedPatient: action.selectedPatient }
    case 'ADD_PATIENT':
      return { ...state }
    case 'DELETE_PATIENT':
      return { ...state }
    case 'START_LOADING':
      return { ...state, isLoadingPatient: true }
    case 'END_LOADING':
      return { ...state, isLoadingPatient: false }
    case 'START_LOADING_UPDATE':
      return { ...state, isLoadingPatientUpdate: true }
    case 'END_LOADING_UPDATE':
      return { ...state, isLoadingPatientUpdate: false }
    case 'START_LOADING_CREATE':
      return { ...state, isLoadingPatientCreate: true }
    case 'END_LOADING_CREATE':
      return { ...state, isLoadingPatientCreate: false }
    default:
      return { ...state }
  }
}
export default patients
