
// ** Initial State
const initialState = {
  allDataFoodEntrie: [],
  dataFoodEntrie: [],
  totalFoodEntrie: 1,
  paramsFoodEntrie: {},
  selectedFoodEntrie: null,
  isLoadingFoodEntrie: false,
  isLoadingFoodEntrieCreate: false,
  isLoadingFoodEntrieUpdate: false,
  foodEntrieStatistics: []

}

const FoodEntries = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA_FOODENTRIE':
      return { ...state, allDataFoodEntrie: action.data }
    case 'GET_DATA_FOODENTRIE':
      return {
        ...state,
        dataFoodEntrie: action.data,
        totalFoodEntrie: action.totalPages,
        paramsFoodEntrie: action.params
      }
    case 'GET_FOODENTRIE_STATISTICS':
      return {
        ...state,
        foodEntrieStatistics: action.data
      }
    case 'GET_FOODENTRIE':
      return { ...state, selectedFoodEntrie: action.selectedFoodEntrie }
    case 'ADD_FOODENTRIE':
      return { ...state }
    case 'DELETE_FOODENTRIE':
      return { ...state }
    case 'START_LOADING':
      return { ...state, isLoadingFoodEntrie: true }
    case 'END_LOADING':
      return { ...state, isLoadingFoodEntrie: false }
    case 'START_LOADING_UPDATE':
      return { ...state, isLoadingFoodEntrieUpdate: true }
    case 'END_LOADING_UPDATE':
      return { ...state, isLoadingFoodEntrieUpdate: false }
    case 'START_LOADING_CREATE':
      return { ...state, isLoadingFoodEntrieCreate: true }
    case 'END_LOADING_CREATE':
      return { ...state, isLoadingFoodEntrieCreate: false }
    default:
      return { ...state }
  }
}
export default FoodEntries
