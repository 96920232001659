// ** Initial State
const initialState = {
  allDataUser: [],
  dataUser: [],
  totalUser: 1,
  paramsUser: {},
  selectedUser: null,
  isLoadingUser: false,
  isLoadingUserUpdate: false,
  isLoadingUserCreate: false

}

const users = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA_USER':
      return { ...state, allDataUser: action.data }
    case 'GET_DATA_USER':
      return {
        ...state,
        dataUser: action.data,
        totalUser: action.totalPages,
        paramsUser: action.params
      }
    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser }
    case 'ADD_USER':
      return { ...state }
    case 'DELETE_USER':
      return { ...state }
    case 'START_LOADING':
      return { ...state, isLoadingUser: true }
    case 'END_LOADING':
      return { ...state, isLoadingUser: false }
    case 'START_LOADING_UPDATE':
      return { ...state, isLoadingUserUpdate: true }
    case 'END_LOADING_UPDATE':
      return { ...state, isLoadingUserUpdate: false }
    case 'START_LOADING_CREATE':
      return { ...state, isLoadingUserCreate: true }
    case 'END_LOADING_CREATE':
      return { ...state, isLoadingUserCreate: false }
    default:
      return { ...state }
  }
}
export default users
